<template>
  <div class="favorites">
    <Collection />
  </div>
</template>

<script>
  import Collection from '../components/favorites/Collection'

  export default {
    name: 'Home',

    components: {
      Collection,
    },
  }
</script>