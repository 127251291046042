  /**
   * Collection component contains a collection the favorite citys 
   */
<template>
  <div class="collection">
    <h1 class="text-h2 mb-5">Favorites:</h1>
    <v-row
      v-if="favorites.length > 0"
      class="cards"
      align="center"
      justify="center"
    >
      <v-card v-for="(item, i) in favorites" :key="i" flat :class="`card`" @click="favoriteClick(item)">
        <v-btn
          @click="removeFromFavorites(item.cityName)"
          title="remove from favorites"
          class="delete"
          color="red"
          small
          icon
          ><v-icon>mdi-close</v-icon></v-btn
        >
        <h2>{{ item.cityName }}</h2>
        <v-img
          transition="scale-transition"
          width="128"
          :src="`img/weather-icons/${item.icon}.svg`"
        ></v-img>
        <p class="max">
          {{
            temperatureScale
              ? item.temperature.metric
              : item.temperature.imperial
          }}&deg;
        </p>
        <!-- <p>{{ item.Temperature.Maximum.Value }}/{{ item.Temperature.Minimum.Value }}</p> -->
      </v-card>
    </v-row>
    <div v-else class="no-favs">
      <h3>No cities added to favorites yet</h3>
      <h4>To add a city to favorites press the <v-icon>mdi-heart-outline</v-icon> icon to or remove from favorites.</h4>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Collection",

  data: () => ({}),
  methods: {
    removeFromFavorites: function (cityName) {
      this.$store.commit("removeFromFavorites", cityName);
    },
    favoriteClick: function (item) {
      console.log(item);
      this.$store.dispatch("currentConditions", {city: {Key: item.key, LocalizedName: item.cityName, Country: {ID: item.countryId}}});
      this.$router.push("/");
    },
  },
  computed: {
    ...mapState(["favorites", "temperatureScale"]),
  },
};
</script>
<style lang="scss" scoped>
.collection {
  padding: 30px;
}
.cards {
  padding: 30px;
  padding-left: 50px;
  width: 100%;
}
.card {
  position: relative;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 2px solid #0e1a2888;
  border-radius: 20px;
  padding: 10px;
  margin-bottom: 20px;
  .delete {
    position: absolute;
    bottom: 0;
    right: 0px;
  }
}
</style>

